// auth.guard.ts
import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
import { LoginAuthService } from './login-auth-service';

@Injectable({
  providedIn: 'root',
})
export class LoginAuthGuard implements CanActivate {
  constructor(
    private loginAuthService: LoginAuthService,
    private router: Router
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    /* setting token expiration date would help to reset the token in the local storage thus increasing security */
    if (this.loginAuthService.getIsLogged()) {
      this.loginAuthService.setLogoutInfo();
      this.router.navigate(['/login']);
      return false;
    }
    return true;
  }
}
