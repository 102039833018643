<router-outlet></router-outlet>
<si-loading-spinner
  *ngIf="loading | async"
  style="position: absolute; top: 40%; left: 50%; height: 200px; color: black"
></si-loading-spinner>

<si-inline-notification
  *ngIf="errorMessage"
  class="mb-6 inline-notification show"
  severity="danger"
  heading="Error"
  [message]="errorMessage"
></si-inline-notification> 

