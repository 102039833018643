import {
  Component,
  Renderer2,
  ChangeDetectorRef,
  AfterViewInit,
} from '@angular/core';
import { ThemeType } from '@simpl/element-ng';
import { Observable } from 'rxjs';
import { CONSTANT } from './constants';
import { LoaderService } from './services/http-loader/loader.service';
import { HttpService } from './services/http-service';
import { SettingsService } from './services/settings.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements AfterViewInit {
  selectedValue = this.settingsService.language;
  loading!: Observable<boolean>;
  initialDelay = true;
  blockingSpinner = true;
  errorMessage: string | null = null;
  animationState = 'out';

  toggleAnimation() {
    this.animationState = this.animationState === 'in' ? 'out' : 'in';
  }
  constructor(
    private settingsService: SettingsService,
    private renderer: Renderer2,
    public loaderService: LoaderService,
    private cdr: ChangeDetectorRef,
    private httpService: HttpService
  ) {
  }

  ngAfterViewInit(): void {
    this.loading = this.loaderService.loading$;
console.log(this.loaderService.loading$);

    this.cdr.detectChanges();
  }

  indicatingHttpErrorMessage = () => {
    this.httpService.errorStatus$.subscribe((erroeStatusCode) => {
      if (erroeStatusCode || erroeStatusCode === 0) {
        for (const statusCode in CONSTANT.ERROR) {
          if (
            CONSTANT.ERROR.hasOwnProperty(erroeStatusCode) &&
            statusCode === erroeStatusCode.toString()
          ) {
            const message = CONSTANT.ERROR[statusCode];
            this.errorMessage = message;
            setTimeout(() => {
              this.errorMessage = null;
            }, 5000);
          }
        }
      }
    });
  };

  ngOnInit(): void {
    this.indicatingHttpErrorMessage();
    this.settingsService.value$.subscribe((currenttheme: ThemeType) => {
      if (currenttheme === CONSTANT.LIGHT) {
        this.renderer.addClass(document.body, CONSTANT.LIGHT_CUSTOM_THEME);
        this.renderer.removeClass(document.body, CONSTANT.DARK_CUSTOM_THEME);
      } else {
        this.renderer.removeClass(document.body, CONSTANT.LIGHT_CUSTOM_THEME);
        this.renderer.addClass(document.body, CONSTANT.DARK_CUSTOM_THEME);
      }
    });
  }
}
