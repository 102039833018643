import { Component, inject, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import {
  MenuItem,
  ModalRef,
  selectMenuItemById,
  SiThemeService,
  ThemeType,
} from '@simpl/element-ng';
import { CONSTANT } from 'src/app/constants';
import { SpecsTranslatorService } from 'src/app/services/specs-translator-service';
import { SettingsService } from '../../services/settings.service';
import { HttpErrorResponse } from '@angular/common/http';
import { RuleStreamTokenService } from 'src/app/services/rule-stream-token-service/token-generator.service';
import { LoginAuthService } from 'src/app/services/login-navigation-services/login-auth-service';
import { GuardedGateKeepService } from 'src/app/services/login-navigation-services/guarded-gatekeeper';
import { ToasterService } from 'src/app/services/toster-service';

@Component({
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss'],
})
export class LandingComponent {
  protected showPassword = false;
  form = this.buildForm();
  disabledOtp = true;
  validation = false;
  selectedRole = 'Engineer';
  isConfirmEmailDelivered = false;
  logingInIndicator = false;
  invalidUserNameOrPasswordResponse: null | string = "'password doesn't match'";
  enteredOtpMessage = '';
  invalidCredentials = null;
  showEnterOtpInputBox = false;
  controlNameTranslateKeyMap = CONSTANT.CONTROL_NAME_TRANSLATE_KEY_MAP;
  errorCodeTranslateKeyMap = CONSTANT.ERROR_CODE_TRANSLATE_KEY_MAP;
  availableLanguages = this.settingsService.availableLanguages;
  trackByIndex = (index: number) => index;
  private languageItems: MenuItem[] =
    this.settingsService.availableLanguages.map((language: any) => ({
      id: language,
      title: `LANGUAGE.${language.toUpperCase()}`,
      action: () => this.switchLanguage(language),
    }));
  @ViewChild('resetTemplate') resetTemplate!: TemplateRef<any>;
  public ref?: ModalRef<unknown>;
  reset = {
    newPasssword: '',
    confirmPassword: '',
  };
  theme: ThemeType = 'light';
  translateObject!: { [key: string]: { [key: string]: string } };
  private themeService = inject(SiThemeService);
  constructor(
    private settingsService: SettingsService,
    private specsTranslatorService: SpecsTranslatorService,
    private router: Router,
    private ruleStreamTokenService: RuleStreamTokenService,
    private loginAuthService: LoginAuthService,
    private gaurdService: GuardedGateKeepService,
    private tosterService: ToasterService
  ) {
    this.languageItems = selectMenuItemById(
      this.languageItems,
      this.settingsService.language
    );

    this.translateObject = this.specsTranslatorService.translaterConstant;
  }

  private buildForm() {
    return new FormGroup({
      name: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required]),
      generatedOtp: new FormControl('', [
        Validators.required,
        Validators.pattern('^[0-9]{6}$'),
      ]),
      emailId: new FormControl('', [Validators.required]),
      license: new FormControl('', [Validators.required]),
    });
  }

  themeToggleQuickAction = {
    title: 'APP.NAV.THEME',
    icon: 'element-economy',
    action: () => this.toggleTheme(),
  };
  private toggleTheme(): void {
    this.themeService.resolvedColorScheme === 'light'
      ? this.switchTheme('dark')
      : this.switchTheme('light');
  }

  private switchTheme(theme: ThemeType): void {
    this.themeService.applyThemeType(theme);
    this.themeToggleQuickAction.icon =
      this.themeService.resolvedColorScheme === 'dark'
        ? 'element-sun'
        : 'element-economy';
  }

  onRoleChange(selectedRole: string) {
    /* on select  of the role patch the value to empty */
    this.invalidCredentials = null;
    this.showEnterOtpInputBox = false;
    if (selectedRole) {
      this.form.patchValue({
        name: '',
        password: '',
        emailId: '',
        license: '',
        generatedOtp: '',
      });
    }
    console.log(this.form);
  }

  cancel() {}

  switchLanguage(language: string): void {
    this.settingsService.setLanguage(language);
    this.languageItems = selectMenuItemById(this.languageItems, language);
  }

  onClickOfGenerateOtp(): void {
    const { name, password, emailId, license } = this.form.value;
    if (this.selectedRole === 'Engineer') {
      const userLoginInfoBody = {
        userName: name,
        userPassword: password,
      };
      this.gaurdService.generateOtpEngineer(userLoginInfoBody).subscribe({
        next: (generatedOtpResult) => {
          if (generatedOtpResult) {
            /* if it has accepted key then generate otp */
            if (generatedOtpResult?.data) {
              this.invalidCredentials = null;
              this.showEnterOtpInputBox = true;
              this.isConfirmEmailDelivered = true;
              setTimeout(() => {
                this.isConfirmEmailDelivered = false;
              }, 5000);
            }
          }
        },
        error: (error: HttpErrorResponse) => {
          this.invalidCredentials = error.error;
          this.showEnterOtpInputBox = false;
          console.log(error);
        },
      });
    } else {
      const loginAdminVerifyLicenceBody = {
        userEmailId: emailId,
        licenseKey: license,
      };

      this.gaurdService
        .generateOtpForAdmin(loginAdminVerifyLicenceBody)
        .subscribe({
          next: (generatedOtpResult) => {
            if (generatedOtpResult) {
              /* if it has accepted key then generate otp */
              if (generatedOtpResult?.data) {
                this.invalidCredentials = null;
                this.showEnterOtpInputBox = true;
                this.isConfirmEmailDelivered = true;
                setTimeout(() => {
                  this.isConfirmEmailDelivered = false;
                }, 5000);
              }
            }
          },
          error: (error: HttpErrorResponse) => {
            console.log(error);
            this.showEnterOtpInputBox = false;
            this.invalidCredentials = error.error;
          },
        });
    }
  }

  login(): void {
    const { name, emailId, generatedOtp } = this.form.value;
    if (this.selectedRole === 'Engineer') {
      this.ruleStreamTokenService.removeToken();
      /* create headers implimentaion and params */
      const verifyOtpBodyForEngineer = {
        userName: name,
        otp: generatedOtp,
      };
      /* verifying otp */
      this.gaurdService.verifyOtpEngineer(verifyOtpBodyForEngineer).subscribe({
        next: (verifyedOtp) => {
          console.log(verifyedOtp);
          if (verifyedOtp?.data?.mes) {
            this.enteredOtpMessage = 'correct otp';
          }
          if (verifyedOtp?.data?.mes === 'correct otp') {
            if (
              verifyedOtp?.data?.roleId === 0 &&
              verifyedOtp?.data?.roleName === 'user'
            ) {
              this.logingInIndicator = true;
              this.ruleStreamTokenService.setToken(
                verifyedOtp?.data.token.token
              );
              localStorage.setItem(
                CONSTANT.USER_INFORMATION,
                JSON.stringify(verifyedOtp?.data)
              );
              localStorage.setItem(
                CONSTANT.USER_ID,
                JSON.stringify(verifyedOtp?.data.userId)
              );
              this.loginAuthService.setLoginInfo();
              this.tosterService.showToast(
                'success',
                'Engineer verified.',
                'Engineer has been verified successfully'
              );
              this.router.navigate(['/main/home']);
            }

            if (
              verifyedOtp?.data?.roleId === 2 &&
              verifyedOtp?.data?.roleName === 'commercial'
            ) {
              this.logingInIndicator = true;
              this.ruleStreamTokenService.setToken(
                verifyedOtp?.data.token.token
              );
              localStorage.setItem(
                CONSTANT.USER_INFORMATION,
                JSON.stringify(verifyedOtp?.data)
              );
              localStorage.setItem(
                CONSTANT.USER_ID,
                JSON.stringify(verifyedOtp?.data.userId)
              );
              this.loginAuthService.setLoginInfo();
              this.tosterService.showToast(
                'success',
                'User verified.',
                'User has been verified successfully'
              );
              this.router.navigate(['/main/commercial']);
            }
          }
        },
        error: (error: HttpErrorResponse) => {
          console.log(error);
          this.enteredOtpMessage = error.error;
          this.tosterService.showToast(
            'danger',
            'Engineer not verified.',
            'User verification failed.'
          );
        },
      });
    } else {
      const verifyOtpBodyForAdminstrator = {
        email: emailId,
        otp: Number(generatedOtp),
        subscribe: false
      }
      /* verifying otp */
      this.gaurdService
        .verifyOtpForAdmin(verifyOtpBodyForAdminstrator)
        .subscribe({
          next: (verifyedOtp) => {
            if (verifyedOtp?.data?.mes) {
              this.enteredOtpMessage = 'correct otp';
            }
            if (verifyedOtp?.data?.mes === 'correct otp') {
              this.logingInIndicator = true;
              /* added the user info here */
              localStorage.setItem(
                CONSTANT.USER_INFORMATION,
                JSON.stringify(verifyedOtp?.data)
              );
              localStorage.setItem(
                CONSTANT.ADMIN_ID,
                JSON.stringify(verifyedOtp?.data.userId)
              );
              this.tosterService.showToast(
                // Invalid credentials
                'success',
                'Admin verified.',
                'Admin has been verified successfully'
              );
              this.router.navigate(['/main/admin']);
            }
          },
          error: (error: HttpErrorResponse) => {
            console.log(error);
            this.enteredOtpMessage = error.error;

            this.tosterService.showToast(
              'danger',
              'Admin not verified.',
              'Admin verification failed.'
            );
          },
        });
    }
  }

  subscribe(): void {
    this.router.navigate(['/subscribe']);
  }

  onClickOfGenerateOtpKeyDownEvent(event: KeyboardEvent) {
    if (event.key === CONSTANT.ENTER || event.key === CONSTANT.SPACE) {
      this.onClickOfGenerateOtp();
    }
  }
  onClickOfSubscribepKeyDownEvent(event: KeyboardEvent) {
    if (event.key === CONSTANT.ENTER || event.key === CONSTANT.SPACE) {
      this.subscribe();
    }
  }
}
