import { Injectable } from '@angular/core';
import { CONSTANT } from 'src/app/constants';

@Injectable({
  providedIn: 'root',
})
export class RuleStreamTokenService {
  private tokenKey = CONSTANT.ACCESS_TOKEN;

  setToken(token: string): void {
    localStorage.setItem(this.tokenKey, token);
  }

  getToken(): string | null {
    return localStorage.getItem(this.tokenKey);
  }

  removeToken(): void {
    localStorage.removeItem(this.tokenKey);
  }
}
