import { Injectable } from '@angular/core';
import { CONSTANT } from 'src/app/constants';

@Injectable({
  providedIn: 'root',
})
export class LoginAuthService {
  private isLogged = false;

  /* for every new tab redirect to logic just use session */
  setLoginInfo() {
    this.isLogged = true;
    sessionStorage.setItem(CONSTANT.USER_LOGGIN, JSON.stringify(this.isLogged));
  }

  setLogoutInfo() {
    this.isLogged = false;
    sessionStorage.setItem(CONSTANT.USER_LOGGIN, JSON.stringify(this.isLogged));
  }

  getIsLogged() {
    return JSON.parse(sessionStorage.getItem(CONSTANT.USER_LOGGIN) as string);
  }

  removeLoggedInfo() {
    sessionStorage.removeItem(CONSTANT.USER_LOGGIN);
  }
}
