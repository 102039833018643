import { SidePanelMode, SidePanelSize, ThemeType } from '@simpl/element-ng';
import { TokenBodyInterface } from './services/specs.interfaces';
/* this ts file represent's all the comman variable or the function that act's as variable */
/* form error messages */
const errorCodeTranslateKeyMap = new Map<string, string>([
  ['name.required', 'Name is required'],
  ['password.required', 'Password is required'],
  ['cid.required', 'Cid is required'],
  ['email.required', 'Email is required'],
  ['email.pattern', 'Invalid email address'],
  ['officeEmail.pattern', 'Invalid email address'],
  ['projectEmailId.required', 'Email is required'],
  ['projectEmailId.pattern', 'Invalid email address'],
  ['customerEmail.required', 'Email is required'],
  ['customerOfficeEmail.required', 'Office Email is required'],
  ['email.email', 'Email is invalid'],
  ['projectEmailId.email', 'Email is invalid'],
  ['customerEmail.email', 'Email is invalid'],
  ['customerOfficeEmail.email', 'Office Email is invalid'],
  ['officeEmail.email', 'Office Email is invalid'],
  ['generatedOtp.required', 'Otp is required'],
  ['generatedOtp.pattern', 'OTP must be a 6-digit number.'],
  ['projectName.required', 'Project Name is required'],
  ['manager.required', 'Manager is required'],
  ['location.required', 'Location is required'],
  ['customerName.required', 'Customer Name is required'],
  ['customerName.pattern', 'Special Character"s are not allowed'],
  ['customerLocation.required', 'Customer Location is required'],
  ['addressLine1.required', 'Address Line 1 is required'],
  ['addressLine2.required', 'Address Line 2 is required'],
  ['contactPerson.required', 'Contact Person is required'],
  ['designation.required', 'Designation is required'],
  ['CustomerDesignation.required', 'Designation is required'],
  ['projectDesignation.required', 'Designation is required'],
  ['constactNumber.required', 'Contact Number is required'],
  ['projectContactNumber.required', 'Contact Number is required'],
  ['customerContactNumber.required', 'Contact Number is required'],
  ['officeEmail.required', 'Official Email is required'],
  ['country.required', 'Country is required'],
  ['state.required', 'State is required'],
  ['zip.required', 'Zip is required'],
  ['projectManager.required', 'Project Manager is required'],
  ['projectLocation.required', 'Project Location is required'],
  ['enquiryDetails.required', 'Enquiry Details is required'],
  ['referenceDrawingNo.required', 'Reference Drawing No is required'],
  ['jobSpecifiedRemarks.required', 'Job Specified Remarks is required'],
  ['endCustomer.required', 'End Customer is required'],
  ['enquiryNumber.required', 'Enquiry Number is required'],
  ['enquiryDate.required', 'Enquiry Date is required'],
  ['customerEnquiryNumber.required', 'Customer Enquiry Number is required'],
  ['CustomerEnquiryDate.required', 'Customer Enquiry Date is required'],
  ['consultantName.required', 'Consultant Name is required'],
  ['enqueryDetails.required', 'Enquiry Details is required'],
  ['refDrawingNo.required', 'Reference Drawing No is required'],
  ['jobSpecifiedRemark.required', 'Job Specified Remarks is required'],
  ['company.required', 'Company Name is required'],
  ['owner.required', 'Owner / Manager is required'],
  ['address.required', 'Address is required'],
]);

const controlNameTranslateKeyMap = new Map<string, string>([]);

/* feeder comp info */
const feedersShowAllModalInoList: { items: string[] }[] = [
  {
    items: [
      'ACB Incomer',
      'MCCB Incomer',
      'FSU Incomer',
      'Switch Incomer',
      'Other Incomer',
    ],
  },
  {
    items: [
      'ACB Bus Coupler',
      'MCCB Bus Coupler',
      'FSU Bus Coupler Coupler',
      'Switch Bus Coupler',
      'Other Bus Coupler',
    ],
  },
  {
    items: ['MPCB Bus PT Feeder', 'FSU Bus PT Feeder'],
  },
  {
    items: [
      'MCCB Control Trafo Feeder',
      'FSU Control Trafo Feeder',
      'MPCB Control Trafo Feeder',
    ],
  },
];

let feedesMainPageListInfo: { label: string; template: string }[] = [
  { label: 'ACB Incomer', template: 'ACB Incomer' },
  { label: 'MCCB Incomer', template: 'MCCB Incomer' },
  { label: 'ACB Bus Coupler', template: 'ACB Bus Coupler' },
  { label: 'MCCB Bus Coupler', template: 'MCCB Bus Coupler' },
  { label: 'ACB Power Outgoing', template: 'ACB Power Outgoing' },
  { label: 'MCCB Power Outgoing', template: 'MCCB Power Outgoing' },
  { label: 'MCCB Bus PT Feeder', template: 'MCCB Bus PT Feeder' },
];

/* constant body for login token */
const bodyToGenerateToken: TokenBodyInterface = {
  password: null,
  userID: 'z004w1hp',
  LoginOptions: {
    TCLoginName: '',
    TCLoginPassword: null,
    TCLoginGroup: '',
    TCLoginRole: '',
  },
};

/* Error messages */
const httpStatusCodes: { [key: string]: string } = {
  '0': 'Unknown Error: An unknown error occurred. Please check your network connection and try again.',
  '200': 'Success: The request was successful and data is available.',
  '201': 'Created: The resource has been successfully created.',
  '204': 'No Content: The request was successful, but no data is returned.',
  '400':
    'Bad Request: Your request is invalid or incomplete. Please check and try again.',
  '401':
    'Unauthorized: You are not authorized to access this resource. Please log in or provide valid credentials.',
  '403': "Forbidden: You don't have permission to access this resource.",
  '404': 'Not Found: The requested resource does not exist.',
  '500':
    'Internal Server Error: An unexpected error occurred on the server. Please try again later.',
  '502':
    'Bad Gateway: There is a problem with the server acting as a gateway or proxy.',
  '503':
    'Service Unavailable: The server is temporarily unavailable. Please try again later.',
  '504':
    "Gateway Timeout: The server couldn't receive a timely response from another server.",
  '418':
    "I'm a Teapot: This status code is not meant to be used in real applications; it's a humorous addition to HTTP.",
  '429':
    'Too Many Requests: You have exceeded the rate limit for requests. Please wait and try again.',
};

const originalDate = new Date();

const formattedDate = `${
  originalDate.getMonth() + 1
}/${originalDate.getDate()}/${originalDate.getFullYear()} ${originalDate.getHours()}:${originalDate.getMinutes()}:${originalDate.getSeconds()}`;
console.log(formattedDate, 'formattedDate');

const sessionId = sessionStorage.getItem('rule_stream_session_id');
console.log(sessionId, 'session id');

export const CONSTANT = {
  ERROR_CODE_TRANSLATE_KEY_MAP: errorCodeTranslateKeyMap,
  CONTROL_NAME_TRANSLATE_KEY_MAP: controlNameTranslateKeyMap,

  // Modal Related Constants
  MODAL_LARGE: 'modal-lg',
  MODAL_SMALL: 'modal-md',
  MODAL_XLARGE: 'modal-xl',

  // Themes
  LIGHT_CUSTOM_THEME: 'light-custom-theme',
  DARK_CUSTOM_THEME: 'dark-custom-theme',
  LIGHT: 'light' as ThemeType,
  DARK: 'dark' as ThemeType,

  // Falsy Values
  EMPTY_STRING: '',

  // Data Types
  STRING: 'string',
  NUMBER: 'number',
  BOOLEAN: 'boolean',

  // Keyboard Types
  ENTER: 'Enter',
  SPACE: ' ',
  CANCEL: 'Cancel',

  // Feeders
  FEEDERS_SHOW_ALL_LIST: feedersShowAllModalInoList,
  FEEDER_MAIN_PAGE_LIST: feedesMainPageListInfo,

  // Strings for Anything
  SHOW_ALL: 'Show All',

  // Element Side Panel Mode
  SCROLL: 'scroll' as SidePanelMode,
  REGULAR: 'regular' as SidePanelSize,

  // Numbers
  THOUSAND: 1000,
  CLIENT_CREDENTIALS: 'client_credentials',
  TOKEN_TIME_LIMIT: 3300,

  // Response Type
  RESPONSE_TYPE: 'json' || 'arraybuffer' || 'text',

  // API Related Constants
  BODY_TO_GENERATE_TOKEN: bodyToGenerateToken,
  PROJECT_ID: 'projectId',
  CUSTOMER_INFO: 'customerInformation',
  NEWLY_CREATED_PROJECT: 'newProject',
  LINE_ITEM_CREDENTIALS: 'lineItemCredential',

  /* Local or session storage */
  USER_LOGGIN: 'is_User_Logged_In',
  ACCESS_TOKEN: 'access_token',
  RULE_STREAM_SESSIONID: 'rule_stream_session_id',
  SESSION_ID: sessionId,
  USER_INFORMATION: 'user_information',
  ADMIN_INFORMATION: 'admin_information',
  ADMIN_ID: 'admin_id',
  USER_ID: 'user_id',
  IDS_customer_details: 'ids_customer_details',
  CUSTOMER_DETAILS_SUB_ID: 'customer_detail_sub_partID',
  CONFIGURATION_DATA: 'configueration_information',
  FEEDER_MAPPED_INFORMATION: 'mapped_feeder_Informtion',
  FEEDER_MAPPED_COMPONENTS: 'mapped_feeder_components',
  TRACKING_CONFIGUERAION_STATUS: 'tracking_configuration_status',
  ADDED_FEEDER_COMPONENT_LIST: 'added_feeder_component_list',
  /* Error Code */
  ERROR: httpStatusCodes,
};

Object.freeze(CONSTANT);

/* other way to store constants */
export class Constant {
  public static constantMap = new Map<string, TokenBodyInterface>();

  /* invoke the class using new keyword or class instance or object instance */
  public static readonly bodyToGenerateToken = new Constant(
    'tokenBody',
    bodyToGenerateToken
  );

  constructor(string: string, info: any) {
    Constant.constantMap.set(string, info);
  }
}
