import { Injectable } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { SiThemeService, ThemeType } from '@simpl/element-ng';
import { BehaviorSubject } from 'rxjs';
import { CONSTANT } from '../constants';

@Injectable({ providedIn: 'root' })
export class SettingsService {
  readonly availableLanguages = ['en'];
  private valueSubject = new BehaviorSubject<ThemeType>('light');
  value$ = this.valueSubject.asObservable();

  constructor(
    private translate: TranslateService,
    private themeService: SiThemeService
  ) {
    translate.setDefaultLang('en');
    translate.onLangChange.subscribe(this.storeLanguage);

    const language = this.loadLanguage() ?? translate.getBrowserLang() ?? 'en';
    this.setLanguage(language);

    this.setTheme(this.loadTheme() ?? CONSTANT.LIGHT);
  }

  get language() {
    return this.translate.currentLang;
  }

  get theme() {
    return this.loadTheme() ?? CONSTANT.LIGHT;
  }

  setLanguage(language: string) {
    this.translate.use(language);
  }

  setTheme(theme: ThemeType) {
    this.themeService.applyThemeType(theme);
    this.storeTheme(theme);
    const currenttheme: ThemeType = this.loadTheme() as ThemeType;
    this.valueSubject.next(currenttheme);
  }

  private storeLanguage(event: LangChangeEvent) {
    localStorage.setItem('lang', event.lang);
  }

  private storeTheme(theme: ThemeType) {
    localStorage.setItem('theme', theme);
  }

  private loadLanguage() {
    return localStorage.getItem('lang');
  }

  public loadTheme() {
    return localStorage.getItem('theme') as ThemeType | undefined;
  }
}
