import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map, catchError, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpService } from '../http-service';

@Injectable({
  providedIn: 'root',
})
export class GuardedGateKeepService {
  private loginUrl = environment.AuthServer;

  constructor(private http: HttpClient, private httpService: HttpService) {}

  /* Login Auth service */
  generateOtpEngineer = (body: any): Observable<any> => {
    const url = `${this.loginUrl}/user/login`;
    const generatedOtp$ = this.http.post(url, body).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: HttpErrorResponse) => {
        if (error?.status || error?.status === 0) {
          this.httpService.errorStatusBehaiouralSubject.next(error.status);
        }
        return throwError(() => error);
      })
    );
    return generatedOtp$;
  };

  verifyOtpEngineer = (verifyOtpBody: any): Observable<any> => {
    const url = `${this.loginUrl}/user/verify`;
    const verifyOtp$ = this.http.post(url, verifyOtpBody).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: HttpErrorResponse) => {
        if (error?.status || error?.status === 0) {
          this.httpService.errorStatusBehaiouralSubject.next(error.status);
        }
        return throwError(() => error);
      })
    );
    return verifyOtp$;
  };

  /* Admin user infos */

  generateOtpForAdmin = (body: any): Observable<any> => {
    const url = `${this.loginUrl}/user/adminLogin`;
    const generatedOtp$ = this.http.post(url, body).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: HttpErrorResponse) => {
        if (error?.status || error?.status === 0) {
          this.httpService.errorStatusBehaiouralSubject.next(error.status);
        }
        return throwError(() => error);
      })
    );
    return generatedOtp$;
  };

  verifyOtpForAdmin = (verifyOtpBody: any): Observable<any> => {
    const url = `${this.loginUrl}/user/verifyOtp`;
    const verifyOtp$ = this.http.post(url, verifyOtpBody).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: HttpErrorResponse) => {
        if (error?.status || error?.status === 0) {
          this.httpService.errorStatusBehaiouralSubject.next(error.status);
        }
        return throwError(() => error);
      })
    );
    return verifyOtp$;
  };
}
