import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class HttpService {
  private baseUrl = environment.baseUrl;

  /* observable to notifiy error status */
  public errorStatusBehaiouralSubject = new BehaviorSubject<
    null | string | number
  >(null);
  errorStatus$ = this.errorStatusBehaiouralSubject.asObservable();

  constructor(private http: HttpClient) {}
  private createHeaders(contentType: string = 'application/json'): HttpHeaders {
    const headers = new HttpHeaders({
      'Content-Type': contentType,
    });
    return headers;
  }

  /* param creater */
  private createParams(params: { [key: string]: any }): HttpParams {
    let httpParams = new HttpParams();
    for (const key in params) {
      if (params.hasOwnProperty(key)) {
        httpParams = httpParams.set(key, params[key]);
      }
    }
    return httpParams;
  }

  // GET request
  get<T>(
    endpoint: string,
    options?: {
      headers?: HttpHeaders;
      params?: { [key: string]: any };
      responseType?: any;
    }
  ): Observable<T> {
    const apiUrl = `${this.baseUrl}/${endpoint}`;
    const headers =
      options && options.headers ? options.headers : this.createHeaders();
    const params =
      options && options.params ? this.createParams(options.params) : undefined;
    const responseType =
      options && options.responseType ? options.responseType : 'json';

    return this.http.get(apiUrl, { headers, params, responseType }).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: HttpErrorResponse) => {
        // alert(error.status);s
        if (error?.status || error?.status === 0) {
          this.errorStatusBehaiouralSubject.next(error.status);
        }
        return throwError(() => error);
      })
    );
  }

  // POST request
  post<T>(
    endpoint: string,
    data: any,
    options?: {
      headers?: HttpHeaders;
      params?: { [key: string]: any };
      responseType?: any;
    }
  ): Observable<T> {
    const apiUrl = `${this.baseUrl}/${endpoint}`;
    const headers =
      options && options.headers ? options.headers : this.createHeaders();
    const params =
      options && options.params ? this.createParams(options.params) : undefined;
    const responseType =
      options && options.responseType ? options.responseType : 'json';

    return this.http.post(apiUrl, data, { headers, params, responseType }).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: HttpErrorResponse) => {
        console.log(error?.status);

        if (error?.status || error?.status === 0) {
          this.errorStatusBehaiouralSubject.next(error.status);
        }
        return throwError(() => error);
      })
    );
  }

  // PUT request
  put<T>(
    endpoint: string,
    data: any,
    options?: {
      headers?: HttpHeaders;
      params?: { [key: string]: any };
      responseType?: any;
    }
  ): Observable<T> {
    const apiUrl = `${this.baseUrl}/${endpoint}`;
    const headers =
      options && options.headers ? options.headers : this.createHeaders();
    const params =
      options && options.params ? this.createParams(options.params) : undefined;
    const responseType =
      options && options.responseType ? options.responseType : 'json';

    return this.http.put(apiUrl, data, { headers, params, responseType }).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: HttpErrorResponse) => {
        if (error?.status || error?.status === 0) {
          this.errorStatusBehaiouralSubject.next(error.status);
        }
        return throwError(() => error);
      })
    );
  }

  // DELETE request
  delete<T>(
    endpoint: string,
    options?: {
      headers?: HttpHeaders;
      params?: { [key: string]: any };
      responseType?: any;
    }
  ): Observable<T> {
    const apiUrl = `${this.baseUrl}/${endpoint}`;
    const headers =
      options && options.headers ? options.headers : this.createHeaders();
    const params =
      options && options.params ? this.createParams(options.params) : undefined;
    const responseType =
      options && options.responseType ? options.responseType : 'json';

    return this.http.delete(apiUrl, { headers, params, responseType }).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: HttpErrorResponse) => {
        if (error?.status || error?.status === 0) {
          this.errorStatusBehaiouralSubject.next(error.status);
        }
        return throwError(() => error);
      })
    );
  }
}
