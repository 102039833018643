import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class SpecsTranslatorService {
  constructor(public translate: TranslateService) {}
  translaterConstant = {
    addNewCustomer: {
      location: this.translate.instant('ADD_NEW_CUSTOMER.LOCATION'),
      customerName: this.translate.instant('ADD_NEW_CUSTOMER.CUSTOMER_NAME'),
      addressLine1: this.translate.instant('ADD_NEW_CUSTOMER.ADDRESS_LINE_1'),
      addressLine2: this.translate.instant('ADD_NEW_CUSTOMER.ADDRESS_LINE_2'),
      contactPerson: this.translate.instant('ADD_NEW_CUSTOMER.CONTACT_PERSON'),
      contactNumber: this.translate.instant('ADD_NEW_CUSTOMER.CONTACT_NUMBER'),
      designation: this.translate.instant('ADD_NEW_CUSTOMER.DESIGNATION'),
      officeEmail: this.translate.instant('ADD_NEW_CUSTOMER.OFFICE_EMAIL'),
      email: this.translate.instant('ADD_NEW_CUSTOMER.EMAIL'),
      country: this.translate.instant('ADD_NEW_CUSTOMER.COUNTRY'),
      state: this.translate.instant('ADD_NEW_CUSTOMER.STATE'),
      zip: this.translate.instant('ADD_NEW_CUSTOMER.ZIP'),
      cid: this.translate.instant('ADD_NEW_CUSTOMER.CID'),
    },
    landingPage: {
      userName: this.translate.instant('LANDING_PAGE.USER_NAME'),
      password: this.translate.instant('LANDING_PAGE.PASSWORD'),
      email: this.translate.instant('LANDING_PAGE.EMAIL'),
      enterOTP: this.translate.instant('LANDING_PAGE.ENTER_OTP'),
      login: this.translate.instant('LANDING_PAGE.LOGIN'),
    },
    newProject: {
      projectName: this.translate.instant('NEW_PROJECT.PROJECT_NAME'),
      manager: this.translate.instant('NEW_PROJECT.MANAGER'),
      Location: this.translate.instant('NEW_PROJECT.LOCATION'),
      customerName: this.translate.instant('NEW_PROJECT.CUSTOMER_NAME'),
      customerLocation: this.translate.instant('NEW_PROJECT.CUSTOMER_LOCATION'),
      projectId: this.translate.instant('NEW_PROJECT.PROJECT_ID'),
      actions: this.translate.instant('NEW_PROJECT.ACTIONS'),
      customerEnquiryNumber: this.translate.instant(
        'NEW_PROJECT.CUSTOMER_ENQUIRY_NUMBER'
      ),
      address: this.translate.instant('NEW_PROJECT.ADDRESS'),
      enqueryDetails: this.translate.instant('NEW_PROJECT.ENQUIRY_DETAILS'),
      refDrawingNo: this.translate.instant(
        'NEW_PROJECT.REFERENCE_DRAWING_NUMBER'
      ),
      jobSpecifiedRemarks: this.translate.instant(
        'NEW_PROJECT.JOB_SPECIFIED_REMARKS'
      ),
      name: this.translate.instant('NEW_PROJECT.NAME'),
      customer: this.translate.instant('NEW_PROJECT.CUSTOMER'),
      id: this.translate.instant('NEW_PROJECT.ID')
    },
    projectDetails: {
      projectManager: this.translate.instant('PROJECT_DETAILS.PROJECT_MANAGER'),
      projectLocation: this.translate.instant('PROJECT_DETAILS.LOCATION'),
      enquiryDetails: this.translate.instant('PROJECT_DETAILS.ENQUIRY_DETAILS'),
      referenceDrawingNo: this.translate.instant(
        'PROJECT_DETAILS.REFERENCE_DEAWING_NO'
      ),
      jobSpecifiedRemarks: this.translate.instant(
        'PROJECT_DETAILS.JOB_SPECIFIED_REMARKS'
      ),
      endCustomer: this.translate.instant('PROJECT_DETAILS.END_CUSTOMER'),
      enquiryNumber: this.translate.instant('PROJECT_DETAILS.ENQUIRY_NUMBER'),
      enquiryDate: this.translate.instant('PROJECT_DETAILS.ENQUIRY_DATE'),
      consultantName: this.translate.instant('PROJECT_DETAILS.CONSULTANT_NAME'),
      customerEnquiryNumber: this.translate.instant(
        'PROJECT_DETAILS.CUSTOMER_ENQUIRY_NUMBER'
      ),
      CustomerEnquiryDate: this.translate.instant(
        'PROJECT_DETAILS.CUSTOMER_ENQUIRY_DATE'
      ),
    },
    products: {
      itemNo: this.translate.instant('PRODUCTS.ITEM_NO'),
      productName: this.translate.instant('PRODUCTS.PRODUCT_NAME'),
      designation: this.translate.instant('PRODUCTS.DESIGNATION'),
      description: this.translate.instant('PRODUCTS.DESCRIPTION'),
      qty: this.translate.instant('PRODUCTS.QTY'),
      listPrice: this.translate.instant('PRODUCTS.LIST_PRICE'),
      totalPrice: this.translate.instant('PRODUCTS.TOTAL_PRICE'),
      configurationStatus: this.translate.instant('PRODUCTS.CONFIGURATION_STATUS'),
      reviewStatus: this.translate.instant('PRODUCTS.REVIEW_STATUS'),
      createdBy: this.translate.instant('PRODUCTS.CREATED_BY'),
      checkoutBy: this.translate.instant('PRODUCTS.CHECKOUT_BY'),
      documents: this.translate.instant('PRODUCTS.DOCUMENTS')
    },
    switchBoard: {
      designation: this.translate.instant('SWITCHGEAR.DESIGNATION'),
      treatementOfBusbar: this.translate.instant(
        'SWITCHGEAR.TREATMENT_OF_BUSBAR'
      ),
      ratedCurrent: this.translate.instant('SWITCHGEAR.RATED_CURRENT'),
      transportUnitWidth: this.translate.instant(
        'SWITCHGEAR.TRANSPORT_UNIT_WIDTH'
      ),
      design: this.translate.instant('SWITCHGEAR.DESIGN'),
      busductCableEntry: this.translate.instant(
        'SWITCHGEAR.BUSTDUCTCABLEENTRY'
      ),
      neutralCurrentRating: this.translate.instant(
        'SWITCHGEAR.NEUTRAL_CURRENT_RATING'
      ),
      baseFrame: this.translate.instant('SWITCHGEAR.BASEFRAME'),
      degreeOfProtection: this.translate.instant(
        'SWITCHGEAR.DEGREE_OF_PROTECTION'
      ),
      accessForOgPowerFeeders: this.translate.instant(
        'SWITCHGEAR.ACCESS_FOR_OG_POWER_FEEDERS'
      ),
      fromOfSeperation: this.translate.instant('SWITCHGEAR.FROM_OF_SEPERATION'),
      spaceHeater: this.translate.instant('SWITCHGEAR.SPACE_HEATER'),
      mainBusBarPosition: this.translate.instant(
        'SWITCHGEAR.MAIN_BUSBAR_POSITION'
      ),
      noOfBusSections: this.translate.instant('SWITCHGEAR.NO_OF_BUS_SECTIONS'),
      earthBusBarMaterial: this.translate.instant(
        'SWITCHGEAR.EARTH_BUS_BAR_MATERIAL'
      ),
      liftingArrangent: this.translate.instant(
        'SWITCHGEAR.LIFTING_ARRAGENGENT'
      ),
      rearDoor: this.translate.instant('SWITCHGEAR.REAR_DOOR'),
      icw: this.translate.instant('SWITCHGEAR.ICW'),
      mainBusBarMaterial: this.translate.instant(
        'SWITCHGEAR.MAIN_BUSBAR_MATERIAL'
      ),
      earthBusMaterial: this.translate.instant('SWITCHGEAR.EARTH_BUS_MATRRIAL'),
    },
    acbIncomer: {
      earthBusMaterial: this.translate.instant('ACBINCOMER.FEEDER_NAME'),
      rating: this.translate.instant('ACBINCOMER.RATING'),
      unit: this.translate.instant('ACBINCOMER.UNIT'),
      height: this.translate.instant('ACBINCOMER.HEIGHT'),
      quantity: this.translate.instant('ACBINCOMER.QUANTITY'),
      execution: this.translate.instant('ACBINCOMER.EXECUTION'),
      busLinkMaterial: this.translate.instant('ACBINCOMER.BUS_LINK_MATERIAL'),
      cablingBusDuctEntry: this.translate.instant(
        'ACBINCOMER.CABLING_BUSDUCT_ENTRY'
      ),
      components: this.translate.instant('ACBINCOMER.COMPONENTS'),
      make: this.translate.instant('ACBINCOMER.MAKE'),
    },

    addCustomer: {
      location: this.translate.instant('ADD_CUSTOMER.LOCATION'),
      customerName: this.translate.instant('ADD_CUSTOMER.CUSTOMER_NAME'),
      addressLine1: this.translate.instant('ADD_CUSTOMER.ADDRESS_LINE_1'),
      addressLine2: this.translate.instant('ADD_CUSTOMER.ADDRESS_LINE_2'),
      contactPerson: this.translate.instant('ADD_CUSTOMER.CONTACT_PERSON'),
      contactNumber: this.translate.instant('ADD_CUSTOMER.CONTACT_NUMBER'),
      designation: this.translate.instant('ADD_CUSTOMER.DESIGNATION'),
      officeEmail: this.translate.instant('ADD_CUSTOMER.OFFICE_EMAIL'),
      email: this.translate.instant('ADD_CUSTOMER.EMAIL'),
      country: this.translate.instant('ADD_CUSTOMER.COUNTRY'),
      state: this.translate.instant('ADD_CUSTOMER.STATE'),
      zip: this.translate.instant('ADD_CUSTOMER.ZIP'),
      cid: this.translate.instant('ADD_CUSTOMER.CID')
    },
    
    subscribe: {
      company: this.translate.instant('SUBSCRIBE.COMPANY'),
      owner: this.translate.instant('SUBSCRIBE.OWNER'),
      confirmPassword: this.translate.instant('SUBSCRIBE.CONFIRMPASSWORD'),
      timePeriod: this.translate.instant('SUBSCRIBE.TIMEPERIOD'),
      quantity: this.translate.instant('SUBSCRIBE.QUANTITY')
    }
  };
}
