import { Injectable } from '@angular/core';
import {
  Link,
  SiToastNotificationService,
  ToastStateName,
} from '@simpl/element-ng';

@Injectable({
  providedIn: 'root',
})
export class ToasterService {
  constructor(private toaster: SiToastNotificationService) {}

  /*toaster service for all kind of taosters :-\ */
  showToast(
    state: ToastStateName,
    title: string,
    message: string,
    disableAutoClose?: boolean,
    disableManualClose?: boolean,
    timeout?: number,
    action?: Link
  ) {
    const toast = this.toaster.showToastNotification({
      state,
      title,
      message,
      disableAutoClose,
      disableManualClose,
      timeout,
      action,
    });
    toast.hidden?.subscribe(() => {
      console.log('tosted successfully');
    });
  }
}
