import { NgModule } from '@angular/core';
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import {
  SI_COPYRIGHT_DETAILS,
  SiLandingPageModule,
  SiNavbarModule,
  SiWizardModule,
  SiActionDialogService,
  SiPasswordStrengthModule,
} from '@simpl/element-ng';
import { SiTranslateNgxTModule } from '@simpl/element-ng/ngx-translate';
import { AppComponent } from './app.component';
import { LandingComponent } from './pages/landing/landing.component';
import { SiCardModule } from '@simpl/element-ng';
import { SiFormModule } from '@simpl/element-ng';
import { SiSearchBarModule } from '@simpl/element-ng';
import { NgxDatatableModule } from '@siemens/ngx-datatable';
import { SiDatatableModule } from '@simpl/element-ng';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SiAccordionModule, SiSidePanelModule } from '@simpl/element-ng';
import { SiIconModule } from '@simpl/element-ng';
import { LoaderInterceptor } from './services/http-loader/loder-interceptor';
import { SiLoadingSpinnerModule } from '@simpl/element-ng';
import { RuleStreamTokenInterceptorService } from './services/rule-stream-token-service/rule-stream-interceptor-service';
import { LoginAuthGuard } from './services/login-navigation-services/auth-login-guard-service';
import { SiInlineNotificationModule } from '@simpl/element-ng';
import { SiLanguageSwitcherModule } from '@simpl/element-ng/language-switcher';

const routes: Routes = [
  { path: 'login', component: LandingComponent, canActivate: [LoginAuthGuard] },
  {
    path: 'main',
    loadChildren: () =>
      import('./pages/main/main.module').then((m) => m.MainModule),
  },
  {
    path: 'subscribe',
    loadChildren: () =>
      import('./pages/subscription/subsciption.module').then(
        (m) => m.SubscriptionModule
      ),
  },
  { path: '**', redirectTo: 'login' },
];

export function createTranslateLoader(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

@NgModule({
  declarations: [AppComponent, LandingComponent],
  imports: [
    BrowserAnimationsModule,
    SiNavbarModule,
    SiCardModule,
    HttpClientModule,
    SiFormModule,
    FormsModule,
    SiAccordionModule,
    SiSidePanelModule,
    ReactiveFormsModule,
    SiSearchBarModule,
    SiWizardModule,
    SiLanguageSwitcherModule,
    NgxDatatableModule,
    SiIconModule,
    SiInlineNotificationModule,
    SiDatatableModule,
    SiLoadingSpinnerModule,
    SiLandingPageModule,
    SiPasswordStrengthModule,
    RouterModule.forRoot(routes, { useHash: true }),
    SiTranslateNgxTModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    SiActionDialogService,
    {
      provide: SI_COPYRIGHT_DETAILS,
      useValue: {
        startYear: 2023,
      },
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RuleStreamTokenInterceptorService,
      multi: true,
    },
  ],
  exports: [RouterModule],
  bootstrap: [AppComponent],
})
export class AppModule {}
