//Production Domain
const server = 'https://server.siepan-specs.com';
const application = 'https://www.siepan-specs.com';
//Beta Domain
// const server = 'https://server.beta-specs.com';
// const application = 'https://www.beta-specs.com/';

export const environment = {
  /* for prod all the same except the this pmlbackendUrl */
  production: true,
  baseUrl: `${server}/RestRuleServices/api/v1`,
  AuthServer: `${server}/AuthServer`,
  SRMServer: `${server}/SRMServer`,
  CustomerServer: `${server}/CustomerServer`,
  pricing: `${server}/pricing`,
  scServer: `${server}/scServer`,
  application: `${application}`,
  domain: `${application}`,
  server: `${server}`

};