<si-landing-page
  heading="SPECS"
  subheading=""
  copyright="© Siemens 2021-2023"
  backgroundImageUrl="./assets/images/specs.jpg"
  [subtitle]="'Planning, Engineering & Configuring'"
  [links]="[
    {
      title: 'Corporate Information',
      href: 'http://www.siemens.com/corporate-information'
    },
    { title: 'Privacy Notice', href: 'http://www.siemens.com/privacy-notice' },
    { title: 'Terms of Use', href: 'http://www.siemens.com/terms-of-use' }
  ]"
>
  <form class="px-4" [class.was-validated]="validation">
    <div class="form-check form-check-inline mb-2">
      <input
        type="radio"
        id="custom-radio-6"
        name="custom-radio-1"
        class="form-check-input"
        [required]="validation"
        value="Engineer"
        [(ngModel)]="selectedRole"
        (change)="onRoleChange(selectedRole)"
      />
      <label class="form-check-label" for="custom-radio-6">Sales</label>
    </div>
    <div class="form-check form-check-inline mb-2">
      <input
        type="radio"
        id="custom-radio-7"
        name="custom-radio-1"
        class="form-check-input"
        [required]="validation"
        value="Adminstrator"
        [(ngModel)]="selectedRole"
        (change)="onRoleChange(selectedRole)"
      />
      <label class="form-check-label" for="custom-radio-7">Administrator</label>
    </div>
  </form>
  <form [formGroup]="form">
    <div *ngIf="selectedRole === 'Engineer'">
      <div class="mb-4">
        <label for="name" class="form-label">Username / Email</label>
        <input
          id="name"
          type="email"
          class="form-control"
          formControlName="name"
        />
      </div>
      <div class="mb-4">
        <label for="password" class="form-label">Password</label>
        <div class="position-relative">
          <input
            formControlName="password"
            id="password"
            class="form-control"
            [type]="showPassword ? 'text' : 'password'"
          />
          <i
            class="icon element-show position-absolute top-0 end-0 mt-2 me-4"
            role="button"
            tabindex="0"
            [attr.aria-label]="showPassword ? 'hide password' : 'show password'"
            [class.text-primary]="showPassword"
            (click)="showPassword = !showPassword"
            (keydown.enter)="showPassword = !showPassword"
          ></i>
        </div>
      </div>
    </div>
    <div *ngIf="selectedRole !== 'Engineer'">
      <div class="mb-4">
        <label for="name" class="form-label">Email</label>
        <input
          id="emailId"
          type="email"
          class="form-control"
          formControlName="emailId"
        />
      </div>
      <div class="mb-4">
        <label for="password" class="form-label">License Key</label>
        <div class="position-relative">
          <input
            [type]="showPassword ? 'text' : 'password'"
            formControlName="license"
            id="license"
            class="form-control"
          />
        </div>
      </div>
    </div>

    <div
      *ngTemplateOutlet="
        templateRef;
        context: {
          $implicit: {
            UsererrorMessage: 'Invalid password. Please check and try again',
            apiErrorMessage: 'password doesn\'t match',
            invalidInfo: invalidUserNameOrPasswordResponse
          }
        }
      "
    ></div>
    <div
      *ngTemplateOutlet="
        templateRef;
        context: {
          $implicit: {
            UsererrorMessage:
              'Invalid UserName / Email. Please check and try again',
            apiErrorMessage:
              'Cannot read properties of undefined (reading \'userName\')',
            invalidInfo: invalidUserNameOrPasswordResponse
          }
        }
      "
    ></div>

    <div
      *ngTemplateOutlet="
        templateRef;
        context: {
          $implicit: {
            UsererrorMessage:
              'Invalid UserName / Email. Please check and try again',
            apiErrorMessage:
              'Cannot read properties of undefined (reading \'userEmailId\')',
            invalidInfo: invalidUserNameOrPasswordResponse
          }
        }
      "
    ></div>
    <div
      *ngTemplateOutlet="
        templateRef;
        context: {
          $implicit: {
            UsererrorMessage:
              'Invalid UserName / Email. Please check and try again',
            apiErrorMessage:
              'Cannot read properties of undefined (reading \'userPassword\')',
            invalidInfo: invalidUserNameOrPasswordResponse
          }
        }
      "
    ></div>

    <a
      class="si-title-2"
      *ngIf="
        (form.controls['name'].valid && form.controls['password'].valid) ||
        (form.controls['emailId'].valid && form.controls['license'].valid)
      "
      (click)="onClickOfGenerateOtp()"
      (keydown)="onClickOfGenerateOtpKeyDownEvent($event)"
      tabindex="0"
      style="cursor: pointer"
      >Generate OTP</a
    >

    <div class="text-danger mt-4" *ngIf="invalidCredentials">
      <strong
        ><b>
          Invalid credentials.Please review the information provided.</b
        ></strong
      >
    </div>

    <div *ngIf="isConfirmEmailDelivered" class="mt-4">
      <span>
        <p>
          <si-icon
            icon="
        element-mail
        "
            style="cursor: pointer; width: 50px; height: 50px"
            color="text-info"
          ></si-icon
          ><b class="text-info" style="margin-left: 2px"
            >OTP sent. Check your inbox.
          </b>
        </p>
      </span>
    </div>
    <div
      class="mt-4"
      *ngIf="
        (showEnterOtpInputBox &&
          form.controls['name'].valid &&
          form.controls['password'].valid) ||
        (showEnterOtpInputBox &&
          form.controls['emailId'].valid &&
          form.controls['license'].valid)
      "
    >
      <label for="generatedOtp" class="form-label">Enter OTP</label>
      <input
        id="generatedOtp"
        type="email"
        class="form-control"
        formControlName="generatedOtp"
      />
    </div>
    <div *ngIf="enteredOtpMessage === 'Invalid OTP'" class="error-message mt-2">
      <p>
        <b>
          <si-icon
            icon="
    element-info "
            style="cursor: pointer; width: 20px; height: 20px"
            color="text-danger"
            class="display-12"
          ></si-icon>
          <span class="ml-2"> Invalid OTP. Please check and try again. </span>
        </b>
      </p>
    </div>

    <div *ngIf="enteredOtpMessage === 'correct otp'" class="text-succes mt-2">
      <p>
        <b>
          <si-icon
            icon="element-circle-filled"
            color="status-success"
            stackedIcon="element-state-tick"
            stackedColor="status-success-contrast"
            size="display-12"
          ></si-icon>
          <span class="ml-2"> OTP verified </span>
        </b>
      </p>
    </div>

    <div *ngIf="enteredOtpMessage === 'OTP expired'" class="mt-2">
      <p>
        <b>
          <si-icon
            icon="element-circle-filled"
            color="status-caution"
            style="cursor: pointer; width: 20px; height: 20px"
            stackedIcon="element-state-exclamation-mark"
            stackedColor="status-danger-contrast"
            class="display-12"
          ></si-icon>
          <span class="ml-2">
            <span class="text-warning">
              OTP Expired. Please request a new one.
            </span>
          </span>
        </b>
      </p>
    </div>

    <div class="text-succes mt-2" *ngIf="logingInIndicator">
      <p>
        <b>
          <span class="ml-2">
            <div class="loading-container">
              <span style="margin-left: 5px; margin-top: 3px">
                Logging in. Please wait
              </span>
              <div class="loading-dot"></div>
              <div class="loading-dot"></div>
              <div class="loading-dot"></div>
            </div>
          </span>
        </b>
      </p>
    </div>
    <button
      type="submit"
      class="btn btn-primary mt-8 w-100"
      [disabled]="
        !form.controls['generatedOtp'].value &&
        !form.controls['generatedOtp'].valid
      "
      (click)="login()"
    >
      Log in
    </button>
    &nbsp;
    <p>
      Don't have an account?
      <a
        (click)="subscribe()"
        tabindex="0"
        (keydown)="onClickOfSubscribepKeyDownEvent($event)"
        ><span
          class="text-primary display-14 specs-cursor-pointer"
          style="margin-left: 5px"
          ><strong><b>Subscribe now</b></strong></span
        >
      </a>
    </p>
  </form>
  <br />
</si-landing-page>

<ng-template #templateRef let-data>
  <div
    *ngIf="data.invalidInfo === data.apiErrorMessage"
    class="error-message mb-8"
  >
    <p>
      <b>
        <si-icon
          icon="
  element-info "
          style="cursor: pointer; width: 20px; height: 20px"
          color="text-danger"
          class="display-12"
        ></si-icon>
        <span class="ml-2"> {{ data.UsererrorMessage }} </span>
      </b>
    </p>
  </div>
</ng-template>
